import { render, staticRenderFns } from "./userList.vue?vue&type=template&id=2c51baf7&scoped=true"
import script from "./userList.vue?vue&type=script&lang=js"
export * from "./userList.vue?vue&type=script&lang=js"
import style0 from "./userList.vue?vue&type=style&index=0&id=2c51baf7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c51baf7",
  null
  
)

export default component.exports